
<template>
<div class="card-body" 
	style="color: #2c3e50; text-align: center;" >
  
	<h2>联机手写文本行识别</h2>
		<div class="canvas-container"
			style="background: #faf2f1;"
			>
			<canvas ref="canvas" :width="boardWidth" :height="boardHeight" 
				style=" background: #fff; border: 1px solid #000; "
				@mousedown="onMouseDown" 
				@mousemove="onMouseMove" 
				@mouseup="onMouseUp">
				你的浏览器不支持 canvas，请升级你的浏览器。
			</canvas>
		</div>
		<div>[{{ lastX + ', ' + lastY }}]</div>
		<div></div>

		<h3>
			{{ recognize_result  }}
		</h3>

		<div>
			<el-button type="primary" @click="onReload" round>清屏</el-button>
		</div>
</div>
</template>

<script>
const axios = require("axios")

export default {
	name: 'HandWritingCharRecognition',
	data() {
		return { 
			initX: 0, 
			initY: 0, 
			lastX: 0, 
			lastY: 0,
			isHandWrite: false,
			pointsXY: [],
			allPointsXY: [],
			write_result: [],
			real_time: true,
			recognize_result: '',
			boardWidth: 1800,
			boardHeight: 256,
		}
	},

  mounted() {
    this.initBound()

    let _this = this
    // 添加jsonp回调函数, qq输入法特定
    window['QQShuru'] = {
      HWPanel: {
        ajax_callback: function (res) {
          _this.write_result = res.cand
        }
      }
    }
  },
  methods: {

    // 初始化canvas位置
    initBound() {
      let bound = this.$refs.canvas.getBoundingClientRect()
      this.initX = parseInt(bound.x)
      this.initY = parseInt(bound.y)
      console.log(bound.y)
    },

    onMouseDown(e) {
      console.log('onDown', e)
      this.pointsXY = []
      let cx = e.clientX - this.initX
      let cy = e.clientY - this.initY
      this.lastX = cx
      this.lastY = cy
      console.log(cx, cy, e.clientX, e.clientY)
      this.pointsXY.push([ parseInt( (e.clientX - this.initX)/2) , parseInt( (e.clientY - this.initY)/2 )])
      this.isHandWrite = true
    },

    onMouseMove(e) {
      if (this.isHandWrite) {
        let cx = e.clientX - this.initX
        let cy = e.clientY - this.initY
		this.pointsXY.push([ parseInt( (e.clientX - this.initX)/2) , parseInt( (e.clientY - this.initY)/2 )])
        // 获取2d上下文对象
        let ctx = this.$refs.canvas.getContext('2d')
        // 新建一条路径
        ctx.beginPath()
        ctx.strokeStyle = '#000'
        ctx.fillStyle = '#000'
        ctx.lineWidth = 6
        ctx.lineCap = 'round'
        ctx.moveTo(this.lastX, this.lastY)
        ctx.lineTo(cx, cy)
        ctx.stroke()
        this.lastX = cx
        this.lastY = cy
      }
    },

    onMouseUp(e) { 
		if (this.isHandWrite) {
			this.isHandWrite = false
			if (this.real_time) {
				this.queryTextRealTime()
			} else {
				// ToDo
			}
		}
    },

    queryTextRealTime() { 
		// eslint-disable-next-line camelcase 
		let real_time_trace = this.pointsXY 
		console.log(real_time_trace) 
		var param = {
			'trace': real_time_trace
		}
		console.log(real_time_trace)

		axios({
			headers: {
				"Content-type":"application/json",
				"Token": "hzcU#!2z^fn",
			},
			method:"post",
			url: `/api/real_time_trace`,
			data: JSON.stringify(param)
		}).then((response)=>{

			this.recognize_result = response.data
			console.log(response.data)
		}).catch(
            res => {
              console.log(res.data.res)
            }
        )
    },

	onReload() { 
		if (!this.$refs.canvas) return 
		this.pointsXY = [] 
		this.allPointsXY = []

		axios({
			headers: {
				"Content-type":"application/json",
				"Token": "hzcU#!2z^fn",
			},
			method:"post",
			url: `/api/real_time_trace/clear`,
			data: JSON.stringify({})
		}).then((response)=>{
			console.log(response.data) 
		}).catch(
            res => {
              console.log(res.data.res)
            }
		)

		this.recognize_result = ' '
		let ctx = this.$refs.canvas.getContext('2d')
		ctx.clearRect(0, 0, this.boardWidth, this.boardHeight)
    }

  }
}
</script>

<style>

</style>

